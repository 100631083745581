import { auth } from '@/components/utils/firebase'
import { onAuthStateChanged } from 'firebase/auth'
import { database } from '@shared/database'
import { debounce } from '@/components/utils/debounce'
import { calculateMeshCodes } from '@shared/calculateMeshCodes'

export const state = () => ({
  userDisplayName: null,
  userAuthEmail: null,
  isAuthenticating: true,
  isLoggedIn: false,
  isSigningOut: false,
  completedSetup: false,
  uid: null,
  isSetTelephoneNumber: false,
  isSetLinePay: false,
  isSetLineId: false,
  subscriptionEnder: null,
  isAnonymous: null,
  updateWorksUnsubscribe: null,
  updateCardsUnsubscribe: null,
  updateSumUnsubscribe: null,
  updateHistoriesUnsubscribe: null,
  updateMessageRoomsUnsubscribe: null,
  updateJoinedMessageRoomsUnsubscribe: null,
  updateMessagesUnsubscribe: null
})

export const mutations = {
  updateUid(state, value) {
    state.uid = value
  },
  updateUserDisplayName(state, value) {
    state.userDisplayName = value
  },
  updateIsAuthenticating(state, value) {
    state.isAuthenticating = value
  },
  updateIsLoggedIn(state, value) {
    state.isLoggedIn = value
  },
  updateIsSigningOut(state, value) {
    state.isSigningOut = value
  },
  updateIsSetTelephoneNumber(state, value) {
    state.isSetTelephoneNumber = value
  },
  updateIsSetLinePay(state, value) {
    state.isSetLinePay = value
  },
  updateIsSetLineId(state, value) {
    state.isSetLineId = value
  },
  updateAuthStatus(state, user) {
    const saveSignInCallback = (
      isSetTelephoneNumber,
      isSetLinePay,
      isSetLineId
    ) => {
      state.isSetTelephoneNumber = isSetTelephoneNumber
      state.isSetLinePay = isSetLinePay
      state.isSetLineId = isSetLineId
      state.completedSetup = true
    }

    if (user && !state.isLoggedIn) {
      if (navigator.geolocation) {
        try {
          debounce(this, saveLocation(user), 10000)
          debounce(this, saveSignInTime(user, saveSignInCallback), 10000)
        } catch (error) {
          console.log(error)
        }
      } else {
        try {
          debounce(this, saveSignInTime(user, saveSignInCallback), 10000)
        } catch (error) {
          console.log(error)
        }
      }
    } else {
      state.completedSetup = true
    }
    const isLoggedIn = !!user
    const hasAuthEmail = !!user && !!user.email
    state.userDisplayName = isLoggedIn ? user.displayName : null
    state.uid = isLoggedIn ? user.uid : null
    state.userAuthEmail = hasAuthEmail ? user.email : null
    state.isAuthenticating = false
    state.isLoggedIn = isLoggedIn
    state.isSigningOut = false
    state.isAnonymous = user ? user.isAnonymous : null
  },
  resetState(state) {
    state.uid = null
    state.userDisplayName = null
    state.userAuthEmail = null
    state.isLoggedIn = false
    state.isAuthenticating = true
    state.isSigningOut = false
    state.isAnonymous = null
    state.updateWorksUnsubscribe = null
    state.updateCardsUnsubscribe = null
    state.updateSumUnsubscribe = null
    state.updateMessageRoomsUnsubscribe = null
    state.updateJoinedMessageRoomsUnsubscribe = null
    state.updateMessagesUnsubscribe = null
  },
  updateSubscriptionEnder(state, value) {
    state.subscriptionEnder = value
  },
  setUpdateWorksUnsubscribe(state, value) {
    state.updateWorksUnsubscribe = value
  },
  setUpdateCardsUnsubscribe(state, value) {
    state.updateCardsUnsubscribe = value
  },
  setUpdateSumUnsubscribe(state, value) {
    state.updateSumUnsubscribe = value
  },
  setUpdateHistoriesUnsubscribe(state, value) {
    state.updateHistoriesUnsubscribe = value
  },
  setUpdateMessageRoomsUnsubscribe(state, value) {
    state.updateMessageRoomsUnsubscribe = value
  },
  setUpdateJoinedMessageRoomsUnsubscribe(state, value) {
    state.updateJoinedMessageRoomsUnsubscribe = value
  },
  setUpdateMessagesUnsubscribe(state, value) {
    state.updateMessagesUnsubscribe = value
  }
}

export const actions = {
  setupAuthStateHandler({ commit, state }) {
    const shouldSetupAuthstate =
      !state.isLoggedIn && state.isAuthenticating && !state.isSigningOut
    if (shouldSetupAuthstate) {
      const ender = onAuthStateChanged(auth, (user) => {
        commit('updateAuthStatus', user)
      })

      commit('updateSubscriptionEnder', ender)
    }
  },
  endSubscription({ state, commit }) {
    if (state.subscriptionEnder) {
      state.subscriptionEnder()
      commit('updateSubscriptionEnder', null)
    }
  },
  async signOut({ state, commit, dispatch }) {
    commit('updateIsSigningOut', true)
    try {
      dispatch('endSubscription')
      if (state.updateWorksUnsubscribe) state.updateWorksUnsubscribe()
      if (state.updateCardsUnsubscribe) state.updateCardsUnsubscribe()
      if (state.updateSumUnsubscribe) state.updateSumUnsubscribe()
      if (state.updateMessageRoomsUnsubscribe)
        state.updateMessageRoomsUnsubscribe()
      if (state.updateMessagesUnsubscribe) state.updateMessagesUnsubscribe()
      await auth.signOut()
      commit('resetState')
    } catch (err) {
      console.error('error signing out of firebase', err)
    } finally {
      commit('updateIsSigningOut', false)
    }
  },
  setUnsubscribeUpdateWorks({ commit }, value) {
    commit('setUpdateWorksUnsubscribe', value)
  },
  setUnsubscribeUpdateCards({ commit }, value) {
    commit('setUpdateCardsUnsubscribe', value)
  },
  setUnsubscribeUpdateSum({ commit }, value) {
    commit('setUpdateSumUnsubscribe', value)
  },
  setUnsubscribeUpdateHistories({ commit }, value) {
    commit('setUpdateHistoriesUnsubscribe', value)
  },
  setUnsubscribeUpdateMessageRooms({ commit }, value) {
    commit('setUpdateMessageRoomsUnsubscribe', value)
  },
  setUnsubscribeUpdateJoinedMessageRooms({ commit }, value) {
    commit('setUpdateJoinedMessageRoomsUnsubscribe', value)
  },
  setUnsubscribeUpdateMessages({ commit }, value) {
    commit('setUpdateMessagesUnsubscribe', value)
  }
}

async function saveLocation(user) {
  navigator.geolocation.getCurrentPosition(async (myPos) => {
    const latitude = myPos.coords.latitude
    const longitude = myPos.coords.longitude

    // 緯度と経度からメッシュコードを計算
    const meshCodes = calculateMeshCodes({ lat: latitude, lng: longitude })

    const data = {
      userId: user.uid,
      lat: latitude,
      lng: longitude,
      mesh80km: meshCodes.mesh80km,
      mesh10km: meshCodes.mesh10km,
      mesh1km: meshCodes.mesh1km,
      mesh250m: meshCodes.mesh250m,
      createdAt: new Date(),
      type: 'signIn'
    }
    await database.locationCollection(user.uid).add(data)
  })
}

async function saveSignInTime(user, callback) {
  const userData = await database.userCollection().findById(user.uid)
  if (!userData) return

  await database.userCollection().update(user.uid, {
    lastSignInTime: new Date()
  })

  if (!userData.completeTutorial) return

  const secret = await database.secretCollection(user.uid).all()

  let isSetLineId = false
  let isSetLinePay = false
  let isSetTelephoneNumber = false

  /** secretのidをキーにしてbodyを取得します
   * @type {(id: string)=>(string|undefined)}
   */
  const getSecretBodyById = (id) => {
    const target = secret.find((s) => s.id === id)
    return target ? target.body : undefined
  }

  if (getSecretBodyById('telephone_number')) {
    isSetTelephoneNumber = true
  }

  if (getSecretBodyById('line_pay')) {
    isSetLinePay = true
  }

  if (getSecretBodyById('line_id')) {
    isSetLineId = true
  }

  callback(isSetTelephoneNumber, isSetLinePay, isSetLineId)
}
